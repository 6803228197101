import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import TrackVisibility from "react-on-screen"

import { useAnalytics } from "../hooks"

import {
  Layout,
  SignOffStillLooking,
  BulletListWithIcon,
  Resource,
  SEO,
  RichText,
} from "../components"
import { courseSlug, tutorSlug } from "../utilities"
import { tutorType, courseGroupType } from "../types"

import InstagramLogo from "../images/icons/instagram.svg"
import LinkedInLogo from "../images/icons/linkedIn.svg"
import TwitterLogo from "../images/icons/twitter.svg"

interface Props {
  data: { contentfulTutors: tutorType } & {
    allContentfulCourseTopics: { edges: Array<{ node: courseGroupType }> }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: tutorSlug(data.contentfulTutors.slug),
  })

  const tutor = data.contentfulTutors
  const url =
    process.env.GATSBY_SITE_URL + tutorSlug(data.contentfulTutors.slug)

  const getTutorCourses = (courseTopics: Array<{ node: courseGroupType }>) =>
    courseTopics.filter(({ node }) => {
      /* Returns an array of Course Topics that have at least 1 course with the tutor matching tutor id */
      return node.courses.some((course) =>
        course.tutors.some(
          (courseTutor) => courseTutor.contentful_id === tutor.contentful_id
        )
      )
    })

  const tutorCourseTopics = getTutorCourses(
    data.allContentfulCourseTopics.edges
  )

  return (
    <Layout>
      <SEO
        title={tutor.seo.title}
        description={tutor.seo.description.description}
        image={tutor.seo.socialImage?.file?.url}
        url={url}
      />
      <TrackVisibility partialVisibility once>
        {({ isVisible }) => (
          <header
            className={`tutor wrapperWidth--small header${
              isVisible ? " active" : ""
            }`}
          >
            <div className="tutorAvatar">
              <div className="tutorAvatarPhoto">
                <GatsbyImage
                  className="tutorAvatarPhotoImg"
                  image={tutor.photo.gatsbyImageData}
                  alt={tutor.photo.title}
                />
              </div>
              <div className="tutorAvatarSocialLinks">
                {tutor.linkedInProfile && (
                  <a
                    href={tutor.linkedInProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tutorSocialLink"
                  >
                    <img
                      src={LinkedInLogo}
                      alt="Instagram Logo"
                      title="Instagram Logo"
                    />
                  </a>
                )}
                {tutor.twitterProfile && (
                  <a
                    href={tutor.twitterProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tutorSocialLink"
                  >
                    <img
                      src={TwitterLogo}
                      alt="Instagram Logo"
                      title="Instagram Logo"
                    />
                  </a>
                )}
                {tutor.instagramProfile && (
                  <a
                    href={tutor.instagramProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tutorSocialLink"
                  >
                    <img
                      src={InstagramLogo}
                      alt="Instagram Logo"
                      title="Instagram Logo"
                    />
                  </a>
                )}
              </div>
            </div>
            <div>
              <div className="tutorSection">
                <span className="boldText tutorSectionHeading tutorSectionHeadingDesktop">
                  Tutor
                </span>
                <h1 className="headingLarge tutorHeading">{tutor.name}</h1>
              </div>
              {tutor.qualifications && tutor.qualifications.length > 0 && (
                <div className="tutorSection">
                  <span className="boldText tutorSectionHeading">
                    Qualifications
                  </span>
                  <BulletListWithIcon
                    variant="tutor"
                    icon="trophy"
                    bullets={tutor.qualifications}
                  />
                </div>
              )}
              {tutor.achievements && tutor.achievements.length > 0 && (
                <div className="tutorSection">
                  <span className="boldText tutorSectionHeading">
                    Achievements
                  </span>
                  <BulletListWithIcon
                    variant="tutor"
                    icon="trophy"
                    bullets={tutor.achievements}
                  />
                </div>
              )}
              <div className="tutorSection">
                <span className="boldText tutorSectionHeading">About</span>
                <div className="content tutorSectionContent">
                  <RichText content={tutor.about} />
                </div>
              </div>
            </div>
          </header>
        )}
      </TrackVisibility>
      {tutorCourseTopics.length > 0 && (
        <main className="backgroundGreyLightSuper">
          <div className="wrapperWidth--small wrapperHeight--small tutorCourseTopics">
            <h2 className="headingSmall tutorCourseTopicsHeading">
              {tutor.nameShort}'s courses
            </h2>
            {tutorCourseTopics.map(({ node }) => {
              const courses = node.courses.filter((course) =>
                course.tutors.some(
                  (courseTutor) =>
                    courseTutor.contentful_id === tutor.contentful_id
                )
              )
              return (
                <div className="mb-8" key={node.contentful_id}>
                  <h3 className="boldText">{node.name}</h3>
                  {courses.map((course) => (
                    <Resource
                      key={course.contentful_id}
                      name={course.name}
                      type="video"
                      url={courseSlug(node.slug, course.slug)}
                      external={false}
                    />
                  ))}
                </div>
              )
            })}
          </div>
        </main>
      )}
      <SignOffStillLooking />
    </Layout>
  )
}

export const pageQuery = graphql`
  query getTutor($contentful_id: String!) {
    contentfulTutors(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      nameShort
      slug
      instagramProfile
      linkedInProfile
      twitterProfile
      qualifications
      achievements
      summary {
        summary
      }
      about {
        raw
      }
      photo {
        gatsbyImageData
        title
      }
      seo {
        description {
          description
        }
        socialImage {
          file {
            url
          }
        }
        title
      }
    }
    allContentfulCourseTopics(sort: { fields: name, order: ASC }) {
      edges {
        node {
          contentful_id
          slug
          name
          courses {
            tutors {
              contentful_id
            }
            slug
            name
          }
        }
      }
    }
  }
`
